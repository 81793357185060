import { stringify } from "postcss";
import React, {useEffect, useState} from "react"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { AccountCreate, StaticContext } from "../domain/interfaces";
import Page from "../templates/page"

const ApiGateway = new httpApiGateway()

export default function Account(props: any) {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)
  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
  }, []) // given empty array useEffect run only once

  const [form, setForm] = useState<AccountCreate>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
    cgv: false,
    rgpd: false
  })
  const [status, setStatus] = useState<{
    state: 'idle' | 'loading' | 'success' | 'error',
    error?: String
  }>({
    state: 'idle'
  })

  const handleChange = (key: string) => (event: any) => {
    setForm({...form, [key]: event.target.value})
  }

  const handleCheckboxChange = (key: string) => (event: any) => {
    setForm({...form, [key]: event.target.checked})
  }

  const onConfirm = async () => {
    let response = await api.onCreateAccount(form)
    if (response.error) {
      setStatus({
        state: 'error',
        error: response.error
      })
    } else {
      setStatus({
        state: 'success',
        error: undefined
      })
    }
  }

  return <Page pageContext={context}>
    <div className="pt-10 md:pt-20 md:py-24 flex flex-col items-center">
      <h1 className="text-brand-primary text-2xl font-bold"> Créez votre compte </h1>
       
        <div className="flex flex-col items-center">
            <div className="py-1 text-gray-700"> Déjà un compte ? </div>
            <a href="/signin" className="cursor-pointer text-brand-primary font-bold text-md">
                Je me connecte
            </a>
        </div>
        <div className="flex flex-col md:flex-row mx-auto">

          <img className="mx-10 object-cover" src="/login-page.png" />

        { status.state == 'success' && <div className="mt-5 bg-white border bordery-gray-200 shadow-lg rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full md:w-96 mx-auto">
          <h1 className="text-3xl text-brand-primary mb-4"> Bravo ! 🎉 </h1>
          <p>
              Votre compte a bien été créé, un email de confirmation vous a été envoyé. 
              Merci de confirmer votre email en cliquant sur le lien d'activation. 
          </p>
          <img className="w-14 object-contain mt-4" src="/email-sent.png" />
        </div> }

        { status.state != 'success' && <div className="mt-5 bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-96 mx-2 border border-gray-200 focus-within:md:mx-auto">
            <div className="mb-4">
              <div className="flex flex-row">
                <div className="w-1/2">
                  <label className="block text-grey-darker text-sm font-bold mb-2">
                    Mon prénom
                  </label>
                  <input value={form.firstname} onChange={handleChange('firstname')} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="username" type="text" placeholder="Prénom" />
                </div>
                <div className="w-1/2 pl-2">
                  <label className="block text-grey-darker text-sm font-bold mb-2">
                    Mon nom
                  </label>
                  <input value={form.lastname} onChange={handleChange('lastname')}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="username" type="text" placeholder="Nom" />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Mon adresse email
              </label>
              <input value={form.email} onChange={handleChange('email')} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="username" type="email" placeholder="chamatini@mail.com" />
            </div>
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Mon mot de passe
              </label>
              <input value={form.password} onChange={handleChange('password')} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" placeholder="******************" />
            </div>
            <div className="mb-2">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Je confirme mon mot de passe
              </label>
              <input value={form.password2} onChange={handleChange('password2')} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password2" type="password" placeholder="******************" />
            </div>
            { status.state == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
              <div className="text-md font-normal max-w-full flex-initial">
                  {status.error}
              </div>
            </div> }
            <div className="flex mb-4">
              <label className="flex items-top">
              <input defaultChecked={(form.cgv === true)} onChange={handleCheckboxChange('cgv')} type="checkbox" className="form-checkbox mt-1" />
                <span className="ml-2 text-sm">J’ai lu et j’accepte les <a href="/cgv" target="_blank" className="text-brand-primary-darkest"> conditions générales d’utilisation. </a></span>
              </label>
            </div>
            <div className="flex mb-4">
              <label className="flex items-top">
              <input defaultChecked={(form.rgpd === true)} onChange={handleCheckboxChange('rgpd')} type="checkbox" className="form-checkbox mt-1" />
                <span className="ml-2 text-sm"> 
                  En soumettant ce formulaire, j’accepte que mes informations personnelles soient utilisées exclusivement dans le cadre de ma demande d’inscription et de la relation commerciale qui peut en découler
                </span>
              </label>
            </div>
            <div className="flex space-x-4">
              {form.cgv && form.rgpd ? <button onClick={onConfirm} className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="button">
                Je crée mon compte
              </button> : 
              <button className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" type="button">
                Je crée mon compte
              </button> }
            </div>
        </div> }
        </div>
    </div>

  </Page>
}
