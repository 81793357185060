import React from "react"
import { StaticContext } from "../domain/interfaces"
import HeaderMenu from "../components/Header"
import Footer from "../components/Footer"

import '@brainhubeu/react-carousel/lib/style.css';

const Page: React.FunctionComponent<{pageContext: StaticContext| null, children: React.ReactNode, hideBasket?: boolean}> = ({children, pageContext, hideBasket}) => {

    return (
        <div>
            {pageContext && <> 
                <HeaderMenu contact={pageContext.contact} menu={pageContext.categories}/>
                {children}
                <Footer hideBasket={hideBasket} contact={pageContext.contact} group1={pageContext.footer.group1} group2={pageContext.footer.group2} group3={pageContext.footer.group3} />
            </>
            }
            {pageContext == null && <div className="pt-20 bg-brand-primary-100 h-screen" style={{'zoom': 2}}> 
                <div className="spinner mx-auto">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
                <div className="mx-auto text-brand-primary text-center text-xs"> chargement de la page </div>
            </div>}
        </div>
    )

}

export default Page